<!-- 关注的小组 -->
<template>
  <div class="my_group">
    <!-- <p class="myclass-title">
      <span class="title-text">我的小组</span>
      <span v-show="count>0">（{{ count }}）</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName" :count="count"></headers>

    <!-- 有数据展示 -->
    <div class="group_list">
      <ul v-show="count > 0" class="zhul_xz_gzList">
        <li v-for="(item,index) in list" :key="index" @click="groupDetail(item)">
          <p class="zhul_ft_yqLimg">
            <img :src="item.avatar_url" width="46" height="46" :title="item.group_name" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" >
          </p>
          <div class="zhul_zy_rmjr">
            <div class="zhul_xz_jrlx">
              <p><b :title="item.old_group_name">{{ item.old_group_name }}</b></p>
              <p class="marginTop">
                <img width="14" src="https://newoss.zhulong.com/edu/202007/29/15/121215s9mneexrrvhglpcl.png">
                <span class="red">{{ item.thread_num }}</span>个帖子&nbsp;&nbsp;
                <img width="15" src="https://newoss.zhulong.com/edu/202007/29/44/121244cbondyllgzczt6un.png">
                {{ item.attend_num }}人关注
              </p>
            </div>
          </div>
        </li>
      </ul>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>15"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </div>

    <!-- 无数据展示 -->
    <noData v-show="count===0" ref="noData"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong_group.png" alt="">
      <p>您还没有关注的小组~</p>
    </div> -->

  </div>
</template>

<script>
import { mygroup } from '@/api/center.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  data() {
    return {
      headName: '我的小组',
      msg: '您还没有关注的小组~',
      count: undefined,
      list: [],
      detail_url: '',
      params: {
        page: 1,
        limit: 15
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },

  mounted() {},

  methods: {
    getlist() {
      mygroup(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    groupDetail(item) {
      window.open(item.detail_url, '_blank')
    },

    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }

}
</script>

<style lang="scss" scoped>
.my_group{
	width: 965px;
	// padding: 0 20px 20px 20px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
  font-family: 'PingFangSC-Regular';
}
.myclass-title{
	padding: 26px 60px 12px 10px;
	width: 100%;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border{
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}

.zhul_xz_gzList{
	float: left;
	margin-left: 30px;
	width: 905px;
}
.zhul_xz_gzList li{
	float: left;
	width: 285px;
	padding: 16px 11px;
	margin: 20px 20px 0 0;
	box-shadow: 0 0 8px 0 rgba(0,0,0,0.12);
	cursor: pointer;
}
.zhul_xz_gzList li:nth-of-type(3n){
	margin-right: 0;
}
.zhul_ft_yqLimg{
	float: left;
	width: 46px;
	height: 46px;
	border-radius: 4px;
	overflow: hidden;
}
.zhul_zy_rmjr{
	float: left;
  width: 200px;
  padding-left: 10px;
  line-height: 26px;
	color: #999;
}
.zhul_xz_jrlx {
    float: left;
    width: 100%;
}
.zhul_xz_jrlx p {
    height: 18px;
    overflow: hidden;
    line-height: 18px;
}
.zhul_zy_rmjr b{
    display: block;
    width: 190px;
    height: 24px;
    font-size: 18px;
    color: #444444;
		text-align: left;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.red{
	color:#EE2E2F;
}
.marginTop{
	margin-top:10px;
	text-align: left;
}
.marginTop img{
	vertical-align:sub;
	margin-right: 4px;
	margin-bottom: 1px;
}
.noDataShow{
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
}
.noDataShow img {
	display: block;
	margin: auto;
}
.noDataShow p {
	text-align: center;
	font-size: 14px;
	color: #999999;
	margin-top: 30px;
}
.feny{
	display: inline-block;
	margin-top: 20px;
}

</style>
